import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"


const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 8" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 8</h1>
      <p>Hei mamma!</p>
      <p>Jeg så at det begynte å bli tynt med smekker, så jeg måtte kjøpe en til meg! Takk, Litjen!</p>
      <p>Jeg tenkte jeg skulle ta deg med på en liten "behind the scenes". Her ser du hvordan jeg forbereder En Litjen adventskalender til deg.</p>
      <p>Det hender at jeg må stå opp midt på natta for å fikse gavene til deg. Det er litt jobb, men jeg skylder deg alt i verden for at du passer så fint på meg hele tiden!</p>
      <Images />
      <p>ILU mamma!</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)


const Images = (props) => (
  <StaticQuery
    query={graphql`
      query {
        litjen1: file(relativePath: { eq: "litjen1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        litjen2: file(relativePath: { eq: "litjen2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (<div>
      <Img fluid={data.litjen2.childImageSharp.fluid} />
      <p>Det er litt vanskelig, men jeg får det til.</p>
      <Img fluid={data.litjen1.childImageSharp.fluid} />
    </div>)}
  />
)

export default LitjenLuke
